@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  background-color: #F1F1F1;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  width: 100%;
}

#root {
  min-height: 100%;
}

.upload-form {
  margin: 20px 0;
}

.upload-box {
  border: 2px dashed #ccc;
  padding: 20px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 100%; /* Updated for responsiveness */
  margin-bottom: 10px;
}

.upload-box input[type="file"] {
  display: none;
}

.upload-label {
  display: block;
  text-align: center;
}

.upload-button {
  background-color: #5C8490;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #486b70;
}

.error {
  color: red;
}

.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  width: 100%; /* Updated for responsiveness */
  max-width: 300px; /* Updated for responsiveness */
  text-align: left;
}

.analytics-item {
  margin-bottom: 10px;
}

.analytics-item h3 {
  margin: 0;
}

.text-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.pre-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 400px;
  overflow-y: auto;
}
.blurred-image {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
  filter: blur(10px); /* Adjust the blur intensity as needed */
  opacity: 0.5; /* Optional: Adjust the opacity for a subtle effect */
  z-index: -1; /* Ensure it stays behind other content */
}
/* tailwind.css */
.result-block {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.result-block .summary {
  font-weight: bold;
  color: #333;
}

.result-block .details {
  margin-top: 8px;
  background: #f9f9f9;
  padding: 8px;
  border-radius: 4px;
  overflow: auto;
  
}

.results-page {
  padding: 16px;
}

.results-grid {
  display: grid;
  gap: 16px;
}
.hidden-video {
  display: none;
}
.hidden {
  display: none;
}
